import { defineAsyncComponent } from 'vue';

const components = {
  alert: defineAsyncComponent(() => import(/* webpackChunkName: "components" */ './Alert.vue')),
  captcha: defineAsyncComponent(() => import(/* webpackChunkName: "components" */ './Captcha.vue')),
  'cookie-bar': defineAsyncComponent(() => import(/* webpackChunkName: "components" */ './CookieBar.vue')),
  'exclusive-option': defineAsyncComponent(() => import(/* webpackChunkName: "components" */ './ExclusiveOption.vue')),
  'confirm-button': defineAsyncComponent(() => import(/* webpackChunkName: "components" */ './ConfirmButton.vue')),
  'file-input': defineAsyncComponent(() => import(/* webpackChunkName: "components" */ './FileInput.vue')),
  'form-submit': defineAsyncComponent(() => import(/* webpackChunkName: "components" */ './FormSubmit.vue')),
  'form-validation': defineAsyncComponent(() => import(/* webpackChunkName: "components" */ './FormValidation.vue')),
  'hand-in-round': defineAsyncComponent(() => import(/* webpackChunkName: "components" */ './HandInRound.vue')),
  'input-counter': defineAsyncComponent(() => import(/* webpackChunkName: "components" */ './InputCounter.vue')),
  'open-option': defineAsyncComponent(() => import(/* webpackChunkName: "components" */ './OpenOption.vue')),
  'panel-description': defineAsyncComponent(() => import(/* webpackChunkName: "components" */ './PanelDescription.vue')),
  'progress-bar': defineAsyncComponent(() => import(/* webpackChunkName: "components" */ './ProgressBar.vue')),
  spinner: defineAsyncComponent(() => import(/* webpackChunkName: "components" */ './Spinner.vue')),
  'order-question': defineAsyncComponent(() => import(/* webpackChunkName: "components" */ './OrderQuestion.vue')),
  'spinner-toggle': defineAsyncComponent(() => import(/* webpackChunkName: "components" */ './SpinnerToggle.vue')),
  'spinner-simple': defineAsyncComponent(() => import(/* webpackChunkName: "components" */ './SpinnerSimple.vue')),
  tabs: defineAsyncComponent(() => import(/* webpackChunkName: "components" */ './Tabs.vue')),
  'text-input': defineAsyncComponent(() => import(/* webpackChunkName: "components" */ './TextInput.vue')),
  'track-click-event': defineAsyncComponent(() => import(/* webpackChunkName: "components" */ './TrackClickEvent.vue'))
}

const modals = {
  'modal-confirm': defineAsyncComponent(() => import(/* webpackChunkName: "modals" */ './modal/ModalConfirm.vue'))
}

export default {
  ...components,
  ...modals
}
